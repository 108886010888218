/* Reset and basic styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1F3B74;
  color: #ecf0f1;
  overflow: hidden;
}

/* Container */
.construction-container {
  text-align: center;
  position: relative;
  z-index: 10;
}

/* Title bounce animation */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.title-bounce {
  font-size: 3rem;
  color: #AD7D20;
  animation: bounce 2s infinite;
}

/* Subtitle fade animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.subtitle-fade {
  font-size: 1.5rem;
  color: #AD7D20;
  margin-top: 10px;
  animation: fadeIn 3s ease-in-out;
}
